import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";

import styles from "@components/layouts/vertically-center.module.css";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & PropsWithChildren;

const VerticallyCenter = ({ children, className, ...divProps }: Props) => (
  <div {...divProps} className={classnames(styles.wrapper, className)}>
    {children}
  </div>
);

export default VerticallyCenter;
