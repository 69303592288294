import { Preferences } from "@capacitor/preferences";
import { PropsWithChildren, useEffect, useState } from "react";

import SessionContext from "@components/context/session-context";
import useMountedTracking from "@hooks/use-mounted-tracking";
import Session from "@typing/session";

const SessionProvider = ({ children }: PropsWithChildren) => {
  const [session, setSessionState] = useState<Session | null>(null);
  const isMounted = useMountedTracking();

  // Hydrate the session from storage on initial app load
  useEffect(() => {
    void Preferences.get({ key: "SESSION" }).then(persistedSession => {
      if (persistedSession.value !== null) {
        const hydratedSession = JSON.parse(persistedSession.value) as Session;
        if (isMounted.current) {
          setSessionState(hydratedSession);
        }
        return hydratedSession;
      }
      return null;
    });
  }, [isMounted]);

  const setSession = (session: Session | null) => {
    void Preferences.set({ key: "SESSION", value: JSON.stringify(session) });
    setSessionState(session);
  };

  return <SessionContext.Provider value={{ session, setSession }}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
