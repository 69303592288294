import { createContext } from "react";

import Session from "@typing/session";

type SessionContextType = {
  session: Session | null;
  setSession: (session: Session | null) => void;
};

const SessionContext = createContext<SessionContextType>({
  session: null,
  setSession: () => {
    // eslint-disable-next-line no-console
    console.log("setCurrentUser is not implemented");
  }
});

export default SessionContext;
