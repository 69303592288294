import { useCallback, useState } from "react";

import useMountedTracking from "@hooks/use-mounted-tracking";

type Opener = {
  close: () => void;
  handleDidDismiss: () => void;
  handleDidPresent: () => void;
  isOpen: boolean;
  isPresented: boolean;
  open: () => void;
  toggle: () => void;
};

const useOpener = () => {
  const isMounted = useMountedTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [isPresented, setIsPresented] = useState(false);

  const close = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(false);
    }
  }, [isMounted]);

  const open = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(true);
    }
  }, [isMounted]);

  const toggle = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(previousIsOpen => !previousIsOpen);
    }
  }, [isMounted]);

  const handleDidDismiss = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(false);
      setIsPresented(false);
    }
  }, [isMounted]);

  const handleDidPresent = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(true);
      setIsPresented(true);
    }
  }, [isMounted]);

  const opener: Opener = {
    close,
    handleDidDismiss,
    handleDidPresent,
    isOpen,
    isPresented,
    open,
    toggle
  };

  return opener;
};

export type { Opener };
export default useOpener;
