import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

import LoginForm from "@components/forms/login-form";
import WwwLayout from "@components/layouts/www-layout";

const LoginPage = () => {
  const intl = useIntl();

  return (
    <WwwLayout>
      <Helmet>
        <title>{intl.formatMessage({ id: "components.LoginPage.title" })}</title>
      </Helmet>
      <LoginForm />
    </WwwLayout>
  );
};

export default LoginPage;
