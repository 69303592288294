import { FormattedMessage } from "react-intl";

const NotFound = () => (
  <div>
    <h1>
      <FormattedMessage id="components.NotFoundPage.title" />
    </h1>
    <p>
      <FormattedMessage id="components.NotFoundPage.body" />
    </p>
  </div>
);

export default NotFound;
