import { IonButton, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { ComponentProps } from "react";

import { Opener } from "@hooks/use-opener";

type Props = Omit<
  ComponentProps<typeof IonModal>,
  "isOpen" | "onDidDismiss" | "onDidPresent" | "onIonModalDidDismiss" | "onIonModalDidPresent"
> & {
  opener: Opener;
  title: string;
};

const BaseModal = ({ children, opener, title, ...modalProps }: Props) => (
  <IonModal
    {...modalProps}
    isOpen={opener.isOpen}
    onIonModalDidDismiss={opener.handleDidDismiss}
    onIonModalDidPresent={opener.handleDidPresent}
  >
    {opener.isPresented && (
      <>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle class="text-lg text-bold">{title}</IonTitle>
            <IonButton color="dark" fill="clear" onClick={opener.close} shape="round" size="large" slot="end">
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>{children}</IonContent>
      </>
    )}
  </IonModal>
);

export default BaseModal;
