import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

import styles from "@components/layouts/split-pane.module.css";

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  left: ReactNode;
  right: ReactNode;
};

const SplitPane = ({ className, left, right, ...divProps }: Props) => (
  <div {...divProps} className={classnames(styles.wrapper, className)}>
    <div className={styles.left}>{left}</div>
    <div className={styles.right}>{right}</div>
  </div>
);

export default SplitPane;
