import { IonButton } from "@ionic/react";
import { useEffect, useMemo } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";

type Props<FormData extends FieldValues> = {
  debug?: boolean;
  forceDisabled?: boolean;
  form: UseFormReturn<FormData>;
  messageId: string;
};

const SubmitButton = <FormData extends FieldValues>({
  debug = false,
  forceDisabled = false,
  form,
  messageId
}: Props<FormData>) => {
  const disabled = useMemo(
    () => forceDisabled || !form.formState.isDirty || form.formState.isSubmitting || !form.formState.isValid,
    [forceDisabled, form.formState.isDirty, form.formState.isSubmitting, form.formState.isValid]
  );

  useEffect(() => {
    if (debug) {
      /* eslint-disable no-console */
      console.log("isDirty", form.formState.isDirty);
      console.log("isSubmitting", form.formState.isSubmitting);
      console.log("isValid", form.formState.isValid);
      console.log("errors", form.formState.errors);
      /* eslint-enable no-console */
    }
  }, [debug, form.formState.isDirty, form.formState.isSubmitting, form.formState.isValid, form.formState.errors]);

  return (
    <IonButton disabled={disabled} type="submit">
      <FormattedMessage id={messageId} />
    </IonButton>
  );
};

export default SubmitButton;
