import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { IonItem, IonList, useIonToast } from "@ionic/react";
import { captureException } from "@sentry/browser";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import * as yup from "yup";

import Form from "@components/forms/form";
import Input from "@components/forms/input";
import SubmitButton from "@components/forms/submit-button";
import useMutationErrorHandler from "@hooks/use-mutation-error-handler";
import {
  CreateDemoRequestDocument,
  CreateDemoRequestMutation,
  CreateDemoRequestMutationVariables
} from "@typing/__generated__";
import isNotNullOrUndefined from "@utils/is-not-null-or-undefined";

type FormData = {
  fromAddress: string;
  message: string;
  organizationName: string;
  referralSource: string;
  senderName: string;
};

type Props = {
  onSuccess: () => void;
};

const RequestDemoForm = ({ onSuccess }: Props) => {
  const intl = useIntl();
  const [present] = useIonToast();

  const presentToast = () => {
    void present({
      color: "success",
      cssClass: "text-bold",
      duration: 5000,
      message: intl.formatMessage({ id: "components.RequestDemoForm.success" }),
      position: "top"
    });
  };

  const defaultValues: FormData = {
    fromAddress: "",
    message: "",
    organizationName: "",
    referralSource: "",
    senderName: ""
  };

  const validationSchema: yup.ObjectSchema<FormData> = yup.object().shape({
    fromAddress: yup
      .string()
      .email(intl.formatMessage({ id: "components.RequestDemoForm.errors.email.format" }))
      .required(intl.formatMessage({ id: "components.RequestDemoForm.errors.email.required" })),
    message: yup.string().defined(),
    organizationName: yup
      .string()
      .required(intl.formatMessage({ id: "components.RequestDemoForm.errors.community.required" })),
    referralSource: yup.string().defined(),
    senderName: yup.string().required(intl.formatMessage({ id: "components.RequestDemoForm.errors.name.required" }))
  });

  const form = useForm<FormData>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  });

  const errorHandler = useMutationErrorHandler({ form, intlBase: "components.RequestDemoForm.errors" });

  const [createDemoRequest] = useMutation<CreateDemoRequestMutation, CreateDemoRequestMutationVariables>(
    CreateDemoRequestDocument
  );

  const handleSubmit = () => {
    createDemoRequest({ variables: form.getValues() })
      .then(response => {
        const payload = response.data?.createDemoRequest;
        if (!isNotNullOrUndefined(payload)) return response;
        if (errorHandler(payload)) return response;

        if (payload.success) {
          presentToast();
          onSuccess();
        }

        return response;
      })
      .catch((error: unknown) => {
        captureException(error);
      });
  };

  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <IonList>
        <IonItem lines="full">
          <Input
            errorText={form.formState.errors.fromAddress?.message}
            inputmode="email"
            label={intl.formatMessage({ id: "components.RequestDemoForm.fields.fromAddress.label" })}
            labelPlacement="stacked"
            placeholder={intl.formatMessage({ id: "components.RequestDemoForm.fields.fromAddress.placeholder" })}
            register={form.register("fromAddress")}
            type="email"
          />
        </IonItem>
        <IonItem lines="full">
          <Input
            errorText={form.formState.errors.senderName?.message}
            label={intl.formatMessage({ id: "components.RequestDemoForm.fields.senderName.label" })}
            labelPlacement="stacked"
            placeholder={intl.formatMessage({ id: "components.RequestDemoForm.fields.senderName.placeholder" })}
            register={form.register("senderName")}
            type="text"
          />
        </IonItem>
        <IonItem lines="full">
          <Input
            errorText={form.formState.errors.organizationName?.message}
            label={intl.formatMessage({ id: "components.RequestDemoForm.fields.organizationName.label" })}
            labelPlacement="stacked"
            placeholder={intl.formatMessage({ id: "components.RequestDemoForm.fields.organizationName.placeholder" })}
            register={form.register("organizationName")}
            type="text"
          />
        </IonItem>
        <IonItem lines="full">
          <Input
            errorText={form.formState.errors.referralSource?.message}
            label={intl.formatMessage({ id: "components.RequestDemoForm.fields.referralSource.label" })}
            labelPlacement="stacked"
            placeholder={intl.formatMessage({ id: "components.RequestDemoForm.fields.referralSource.placeholder" })}
            register={form.register("referralSource")}
            type="text"
          />
        </IonItem>
        <IonItem lines="full">
          <Input
            errorText={form.formState.errors.message?.message}
            label={intl.formatMessage({ id: "components.RequestDemoForm.fields.message.label" })}
            labelPlacement="stacked"
            placeholder={intl.formatMessage({ id: "components.RequestDemoForm.fields.message.placeholder" })}
            register={form.register("message")}
            type="text"
          />
        </IonItem>
      </IonList>
      <SubmitButton form={form} messageId="components.RequestDemoForm.submit" />
    </Form>
  );
};

export default RequestDemoForm;
