import { createContext } from "react";

import { Opener } from "@hooks/use-opener";

type DemoModalContextType = {
  demoModalOpener?: Opener;
};

const DemoModalContext = createContext<DemoModalContextType>({});

export default DemoModalContext;
