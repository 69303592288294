import { BaseSyntheticEvent, HTMLProps } from "react";

import useDebouncedSubmit from "@hooks/use-debounced-submit";

// 1. Overwrites onSubmit to require it.
// 2. Uses useDebouncedSubmit to (optionally, but by default) debounce the submit event.
// 3. Disables native form validation since our form library will do that.
type Props = Omit<HTMLProps<HTMLFormElement>, "onSubmit"> & {
  debounce?: boolean;
  onSubmit: (event: BaseSyntheticEvent) => Promise<void>;
};

const Form = ({ debounce = true, onSubmit, ...formProps }: Props) => {
  const onSubmitDebounced = useDebouncedSubmit({ onSubmit });

  return <form noValidate {...formProps} onSubmit={debounce ? onSubmitDebounced : onSubmit} />;
};

export default Form;
