import { IonInput } from "@ionic/react";
import classnames from "classnames";
import { ComponentProps, useState } from "react";
import { FieldPath, FieldValues, UseFormRegisterReturn } from "react-hook-form";

type Props<FormData extends FieldValues> = Omit<
  // Prevent the client from supplying any props that come from the form register
  ComponentProps<typeof IonInput>,
  | "aria-invalid"
  | "disabled"
  | "max"
  | "maxLength"
  | "min"
  | "minLength"
  | "name"
  | "onBlur"
  | "onChange"
  | "onIonBlur"
  | "onIonChange"
  | "pattern"
  | "required"
> & {
  register: UseFormRegisterReturn<FieldPath<FormData>>;
};

const Input = <FormData extends FieldValues>({ className, register, ...ionInputProps }: Props<FormData>) => {
  const [isTouched, setIsTouched] = useState(false);
  const { onBlur, onChange, ref, ...registerProps } = register;
  const isInvalid = ionInputProps.errorText !== undefined && ionInputProps.errorText.length > 0;

  return (
    <IonInput
      {...ionInputProps}
      {...registerProps}
      aria-invalid={isInvalid}
      className={classnames(className, isInvalid && "ion-invalid", isTouched && "ion-touched")}
      onIonBlur={event => {
        setIsTouched(true);
        void onBlur(event);
      }}
      onIonInput={event => {
        void onChange(event);
      }}
      ref={element => {
        ref(element);
      }}
    />
  );
};

export default Input;
