import { useContext } from "react";
import { useIntl } from "react-intl";

import DemoModalContext from "@components/context/demo-modal-context";
import RequestDemoForm from "@components/forms/request-demo-form";
import BaseModal from "@components/overlays/base-modal";

const DemoModal = () => {
  const intl = useIntl();
  const { demoModalOpener } = useContext(DemoModalContext);

  if (demoModalOpener === undefined) return null;

  return (
    <BaseModal opener={demoModalOpener} title={intl.formatMessage({ id: "components.DemoModal.title" })}>
      <div className="p-md">
        <RequestDemoForm onSuccess={demoModalOpener.close} />
      </div>
    </BaseModal>
  );
};

export default DemoModal;
