import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import styles from "@components/logo.module.css";

const Logo = () => (
  <>
    <span className={classnames(styles.logo, styles.logoLeft, "text-xl")}>
      <FormattedMessage id="components.Logo.alumni" />
    </span>
    <span className={classnames(styles.logo, styles.logoRight, "text-xl")}>
      <FormattedMessage id="components.Logo.fire" />
    </span>
  </>
);

export default Logo;
