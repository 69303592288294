import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
// import { buildClientSchema, IntrospectionQuery } from "graphql";
import { ComponentProps, useMemo } from "react";

// import introspectionResult from "@/schema.json";

type Props = Omit<ComponentProps<typeof ApolloProvider>, "client">;

const GraphqlClientProvider = (props: Props) => {
  // introspectionResult is a JSON object generated by the codegen task, it does not seem to add a
  // `kind` value to objects, which it would need to full match the IntrospectionQuery type. The
  // code seems to work fine without it though.
  // const schema = useMemo(() => buildClientSchema(introspectionResult as unknown as IntrospectionQuery), []);

  const cache = useMemo(
    () =>
      new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              // queryName: relayStylePagination(["field1", "field2", ...])
            }
          }
        }
      }),
    []
  );

  const apolloClient = new ApolloClient({
    cache,
    uri: import.meta.env.VITE_API_HOST
  });

  return <ApolloProvider {...props} client={apolloClient} />;
};

export default GraphqlClientProvider;
