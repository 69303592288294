// eslint-disable-next-line import/no-unused-modules
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { browserTracingIntegration, init as initSentry, replayIntegration } from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

import App from "@components/app";

import "@/index.css";

const dsn = import.meta.env.VITE_SENTRY_DSN;

if (dsn) {
  initSentry({
    dsn,
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Session Replay
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // Tracing
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    tracesSampleRate: 1 //  Capture 100% of the transactions
  });
}

const container = document.querySelector("#root");
const root = container ? createRoot(container) : null;

root?.render(
  <HelmetProvider>
    <Helmet>
      <meta content="noindex, nofollow" name="robots" />
      <meta
        content={`
          base-uri 'self';
          child-src 'self' blob:;
          connect-src
            'self'
            data:
            https://*.sentry.io
            http://localhost:3000
            ${import.meta.env.VITE_ACTIONCABLE_URL}
            ${import.meta.env.VITE_API_HOST};
          default-src ;
          font-src
            'self'
            https://fonts.googleapis.com
            https://fonts.gstatic.com
            data:;
          frame-src
            blob:;
          img-src
            'self'
            blob:
            data:
            https://*.cloudfront.net;
          manifest-src
            'self';
          media-src
            'self';
          object-src 'none';
          script-src
            'self'
            data:
            'unsafe-inline'
            https://appleid.cdn-apple.com/
            https://*.ingest.sentry.io;
          style-src
            'self'
            'unsafe-inline'
            https://fonts.googleapis.com;
          worker-src
            'self'
            blob:;
        `}
        httpEquiv="Content-Security-Policy"
      />
    </Helmet>
    <App />
  </HelmetProvider>
);

// eslint-disable-next-line unicorn/prefer-global-this
void defineCustomElements(window);
