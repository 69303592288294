import { useIntl } from "react-intl";

import styles from "@components/images/home-press-logo.module.css";

type Props = {
  brand: string;
};

const HomePressLogo = ({ brand }: Props) => {
  const intl = useIntl();

  return (
    <img
      alt={intl.formatMessage({ id: `components.HomePage.images.logos.${brand}.alt` })}
      className={styles.logo}
      src={intl.formatMessage({ id: `components.HomePage.images.logos.${brand}.src` })}
    />
  );
};

export default HomePressLogo;
