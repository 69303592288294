import { BaseSyntheticEvent, useCallback, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

type UseDebouncedSubmitArguments = {
  onSubmit: (event: BaseSyntheticEvent) => Promise<void>;
};

const useDebouncedSubmit = ({ onSubmit }: UseDebouncedSubmitArguments) => {
  const onSubmitDebounced = useDebouncedCallback(onSubmit, 500, {
    leading: true
  });

  const onSubmitDebouncedCallback = useCallback(
    (event: BaseSyntheticEvent) => {
      event.preventDefault();
      void onSubmitDebounced(event);
    },
    [onSubmitDebounced]
  );

  useEffect(
    () => () => {
      void onSubmitDebounced.flush();
    },
    [onSubmitDebounced]
  );

  return onSubmitDebouncedCallback;
};

export default useDebouncedSubmit;
