import { useLazyQuery } from "@apollo/client";
import { PropsWithChildren, useEffect, useState } from "react";

import CurrentCommunityContext from "@components/context/current-community-context";
import {
  CommunityFragment,
  GetCommunityDocument,
  GetCommunityQuery,
  GetCommunityQueryVariables
} from "@typing/__generated__";

const CurrentCommunityProvider = ({ children }: PropsWithChildren) => {
  const [currentCommunity, setCurrentCommunity] = useState<CommunityFragment | null>(null);

  const hostname = globalThis.location.hostname;
  const parts = hostname.split(".");
  // Technically a subdomain might not exist on an apex domain, but we don't use an apex domain, and
  // it also wouldn't matter since querying the API for the apex hostname would just fail.
  const subdomain = parts[0];

  const [getCommunity, getCommunityQuery] = useLazyQuery<GetCommunityQuery, GetCommunityQueryVariables>(
    GetCommunityDocument,
    {
      variables: { subdomain }
    }
  );

  useEffect(() => {
    void getCommunity();
  }, [getCommunity, subdomain]);

  useEffect(() => {
    if (getCommunityQuery.data?.community) {
      setCurrentCommunity(getCommunityQuery.data.community);
    }
  }, [getCommunityQuery.data?.community]);

  return (
    <CurrentCommunityContext.Provider value={{ currentCommunity, setCurrentCommunity }}>
      {children}
    </CurrentCommunityContext.Provider>
  );
};

export default CurrentCommunityProvider;
