import { useQuery } from "@apollo/client";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import classnames from "classnames";
import {
  accessibilityOutline,
  businessOutline,
  calendarNumberOutline,
  chatboxOutline,
  chevronDown,
  chevronUp,
  clipboardOutline,
  peopleCircleOutline
} from "ionicons/icons";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";

import RequestDemoButton from "@components/buttons/request-demo-button";
import UpperRightBubble from "@components/graphic-elements/upper-right-bubble";
import HomePressLogo from "@components/images/home-press-logo";
import SplitPane from "@components/layouts/split-pane";
import VerticallyCenter from "@components/layouts/vertically-center";
import WwwLayout from "@components/layouts/www-layout";
import styles from "@components/pages/home-page.module.css";
import HomePageParagraph from "@components/typography/home-page-paragraph";
import {
  GetTrendingCommunitiesDocument,
  GetTrendingCommunitiesQuery,
  GetTrendingCommunitiesQueryVariables
} from "@typing/__generated__";

const HomePage = () => {
  const intl = useIntl();

  const logoBrands = ["forbes", "cnbc", "businessInsider", "christensenInstitute", "universityBusiness", "ladders"];
  const featureSection = [
    { icon: accessibilityOutline, name: "directory" },
    { icon: clipboardOutline, name: "jobs" },
    { icon: calendarNumberOutline, name: "events" },
    { icon: chatboxOutline, name: "forum" },
    { icon: businessOutline, name: "employers" },
    { icon: peopleCircleOutline, name: "groups" }
  ];

  const getTrendingCommunities = useQuery<GetTrendingCommunitiesQuery, GetTrendingCommunitiesQueryVariables>(
    GetTrendingCommunitiesDocument,
    { variables: { limit: 9 } }
  );
  const trendingCommunities = getTrendingCommunities.data?.trendingCommunities;

  return (
    <WwwLayout>
      <Helmet>
        <title>{intl.formatMessage({ id: "components.HomePage.title" })}</title>
      </Helmet>
      <div className="flex flex-col h-full">
        <SplitPane
          className="grow"
          left={
            <VerticallyCenter className="p-2xl">
              <h1 className="text-2xl text-bold">
                <FormattedMessage id="components.HomePage.sections.hero.heading" />
              </h1>
              <HomePageParagraph>
                <FormattedMessage id="components.HomePage.sections.hero.description" />
              </HomePageParagraph>
              <div>
                <RequestDemoButton>
                  <FormattedMessage id="components.HomePage.sections.hero.cta" />
                </RequestDemoButton>
              </div>
            </VerticallyCenter>
          }
          right={
            <VerticallyCenter>
              <img
                alt={intl.formatMessage({ id: "components.HomePage.images.hero.alt" })}
                src={intl.formatMessage({ id: "components.HomePage.images.hero.src" })}
              />
            </VerticallyCenter>
          }
        />
        <div className={classnames(styles.sectionAlt, "flex align-center justify-between p-lg")}>
          {logoBrands.map(brand => (
            <HomePressLogo brand={brand} key={brand} />
          ))}
        </div>
        <div className={styles.section}>
          <h2 className="text-xl text-bold">
            <FormattedMessage id="components.HomePage.sections.features.heading" />
          </h2>
          <HomePageParagraph>
            <FormattedMessage id="components.HomePage.sections.features.description" />
          </HomePageParagraph>
          <IonGrid>
            <IonRow>
              {featureSection.map(({ icon, name }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <IonCol className="text-center" key={index} size="12" sizeLg="4" sizeMd="6">
                  <IonIcon aria-hidden="true" color="dark" icon={icon} size="large" />
                  <h3 className="text-lg text-bold text-center">
                    <FormattedMessage id={`components.HomePage.sections.features.${name}.heading`} />
                  </h3>
                  <p className="my-md text-md text-center">
                    <FormattedMessage id={`components.HomePage.sections.features.${name}.description`} />
                  </p>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
        <div className={classnames(styles.section, styles.sectionAlt)}>
          <h2 className="text-xl text-bold">
            <FormattedMessage id="components.HomePage.sections.trending.heading" />
          </h2>
          <IonGrid>
            <IonRow>
              {trendingCommunities?.map(({ community, previousRanking, ranking }) => (
                <IonCol key={community.id} size="12" sizeLg="4" sizeMd="6">
                  <UpperRightBubble
                    className="h-full"
                    color={previousRanking === null || ranking < previousRanking ? "success" : "success"}
                    content={
                      previousRanking === null || ranking < previousRanking ? (
                        <IonIcon icon={chevronUp} />
                      ) : ranking > previousRanking ? (
                        <IonIcon icon={chevronDown} />
                      ) : null
                    }
                  >
                    <IonCard className="grid gap-0 h-full">
                      <img alt={community.formalName} src={community.bgImage} />
                      <IonCardHeader className="flex flex-col">
                        <div className="grow" />
                        <IonCardSubtitle className="flex align-center m-0">
                          <span className="grow text-lg text-bold me-sm">{ranking}</span>
                          <span className="text-bold text-end uppercase">{community.formalName}</span>
                        </IonCardSubtitle>
                      </IonCardHeader>
                    </IonCard>
                  </UpperRightBubble>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
        <SplitPane
          className={classnames(styles.demo, styles.section)}
          left={
            <VerticallyCenter className="p-2xl">
              <h2 className="text-xl text-bold">
                <FormattedMessage id="components.HomePage.sections.demo.heading" />
              </h2>
              <HomePageParagraph>
                <FormattedMessage id="components.HomePage.sections.demo.description" />
              </HomePageParagraph>
              <div>
                <RequestDemoButton color="light">
                  <FormattedMessage id="components.HomePage.sections.demo.cta" />
                </RequestDemoButton>
              </div>
            </VerticallyCenter>
          }
          right={
            <VerticallyCenter>
              <img
                alt={intl.formatMessage({ id: "components.HomePage.images.demo.alt" })}
                src={intl.formatMessage({ id: "components.HomePage.images.demo.src" })}
              />
            </VerticallyCenter>
          }
        />
      </div>
    </WwwLayout>
  );
};

export default HomePage;
