import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

import WwwLayout from "@components/layouts/www-layout";

const CommunitySearchPage = () => {
  const intl = useIntl();

  return (
    <WwwLayout>
      <Helmet>
        <title>{intl.formatMessage({ id: "components.CommunitySearchPage.title" })}</title>
      </Helmet>
      <div />
    </WwwLayout>
  );
};

export default CommunitySearchPage;
