import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ErrorBoundary, withProfiler } from "@sentry/react";
import { createBrowserHistory } from "history";
import { FormattedMessage, IntlProvider } from "react-intl";

import CurrentCommunityProvider from "@components/context/current-community-provider";
import SessionProvider from "@components/context/session-provider";
import GraphqlClientProvider from "@components/graphql-client-provider";
import WwwRoutes from "@components/routes/www-routes";
import messages from "@i18n/en";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

/**
 * Ionic Dark Palette
 * -----------------------------------------------------
 * For more information, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

// import "@ionic/react/css/palettes/dark.always.css";
// import '@ionic/react/css/palettes/dark.class.css';
// import '@ionic/react/css/palettes/dark.system.css';

/* Theme variables */
import "@theme/colors.css";
import "@theme/variables.css";
import "@theme/fonts.css";
import "@theme/utilities/border.css";
import "@theme/utilities/display.css";
import "@theme/utilities/position.css";
import "@theme/utilities/sizing.css";
import "@theme/utilities/spacing.css";
import "@theme/utilities/transform.css";
import "@theme/utilities/typography.css";

setupIonicReact();

const App = () => {
  const history = createBrowserHistory();

  return (
    <IntlProvider locale="en" messages={messages}>
      <SessionProvider>
        <GraphqlClientProvider>
          <CurrentCommunityProvider>
            <IonReactRouter history={history}>
              <IonApp>
                <ErrorBoundary fallback={<FormattedMessage id="components.App.errors.default" />} showDialog>
                  <WwwRoutes />
                </ErrorBoundary>
              </IonApp>
            </IonReactRouter>
          </CurrentCommunityProvider>
        </GraphqlClientProvider>
      </SessionProvider>
    </IntlProvider>
  );
};

const ProfiledApp = withProfiler(App);

export default ProfiledApp;
