import { IonButton } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import { Switch } from "react-router";

import AboutPage from "@components/pages/about-page";
import CommunitySearchPage from "@components/pages/community-search-page";
import HomePage from "@components/pages/home-page";
import LoginPage from "@components/pages/login-page";
import NotFoundPage from "@components/pages/not-found-page";
import RequireLoggedOut from "@components/routes/require-logged-out";
import SentryRoute from "@components/sentry-route";

const WwwRoutes = () => (
  <Switch>
    <SentryRoute exact path="/" render={() => <HomePage />} />
    <SentryRoute exact path="/about" render={() => <AboutPage />} />
    <SentryRoute exact path="/search" render={() => <CommunitySearchPage />} />
    <SentryRoute
      exact
      path="/login"
      render={() => (
        <RequireLoggedOut>
          <LoginPage />
        </RequireLoggedOut>
      )}
    />
    <SentryRoute
      exact
      path="/debug"
      render={() => (
        <IonButton
          onClick={() => {
            throw new Error("This is your first error!");
          }}
        >
          <FormattedMessage id="dictionary.test" />
        </IonButton>
      )}
    />
    <SentryRoute render={() => <NotFoundPage />} />
  </Switch>
);

export default WwwRoutes;
