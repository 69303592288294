import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, ReactNode } from "react";

import styles from "@components/graphic-elements/upper-right-bubble.module.css";
import isNotNullOrUndefined from "@utils/is-not-null-or-undefined";

type Props = Pick<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "className"> &
  PropsWithChildren<{
    color: "danger" | "success" | "warning";
    content: ReactNode;
  }>;

const UpperRightBubble = ({ children, className, color, content }: Props) => {
  if (isNotNullOrUndefined(content)) {
    return (
      <div className={classnames(className, "relative")}>
        <span
          className={classnames(
            styles.bubble,
            styles[color],
            "absolute top-0 right-0 -translate-x-1/2 -translate-y-1/2 flex align-center justify-center rounded-full z-10"
          )}
        >
          {content}
        </span>
        {children}
      </div>
    );
  }

  return children;
};

export default UpperRightBubble;
