import { PropsWithChildren, useContext } from "react";
import { Redirect } from "react-router";

import SessionContext from "@components/context/session-context";
import isNotNullOrUndefined from "@utils/is-not-null-or-undefined";

type Props = PropsWithChildren<{
  redirectTo?: string;
}>;

const RequireLoggedOut = ({ children, redirectTo = "/" }: Props) => {
  const { session } = useContext(SessionContext);

  if (isNotNullOrUndefined(session)) {
    return <Redirect to={redirectTo} />;
  }

  return children;
};

export default RequireLoggedOut;
