import { PropsWithChildren } from "react";

import DemoModalContext from "@components/context/demo-modal-context";
import DemoModal from "@components/overlays/demo-modal";
import useOpener from "@hooks/use-opener";

const DemoModalProvider = ({ children }: PropsWithChildren) => {
  const demoModalOpener = useOpener();
  return (
    <DemoModalContext.Provider value={{ demoModalOpener }}>
      {children}
      <DemoModal />
    </DemoModalContext.Provider>
  );
};

export default DemoModalProvider;
