import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { PropsWithChildren, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import RequestDemoButton from "@components/buttons/request-demo-button";
import DemoModalProvider from "@components/context/demo-modal-provider";
import SessionContext from "@components/context/session-context";
import Logo from "@components/logo";

const WwwLayout = ({ children }: PropsWithChildren) => {
  const { session, setSession } = useContext(SessionContext);
  const location = useLocation();
  const path = location.pathname;

  const handleLogout = () => {
    setSession(null);
  };

  return (
    <DemoModalProvider>
      <IonPage>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>
              <Logo />
            </IonTitle>
            <IonButtons slot="end">
              <IonButton disabled={path === "/"} href="/">
                <FormattedMessage id="components.WwwLayout.nav.home" />
              </IonButton>
              {session ? (
                <IonButton onClick={handleLogout}>
                  <FormattedMessage id="components.WwwLayout.nav.logout" />
                </IonButton>
              ) : (
                <IonButton disabled={path === "/login"} href="/login">
                  <FormattedMessage id="components.LoginPage.title" />
                </IonButton>
              )}
              <IonButton disabled={path === "/search"} href="/search">
                <FormattedMessage id="components.WwwLayout.nav.search" />
              </IonButton>
              <IonButton disabled={path === "/about"} href="/about">
                <FormattedMessage id="components.WwwLayout.nav.about" />
              </IonButton>
              <RequestDemoButton>
                <FormattedMessage id="components.WwwLayout.nav.cta" />
              </RequestDemoButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>{children}</IonContent>
      </IonPage>
    </DemoModalProvider>
  );
};

export default WwwLayout;
