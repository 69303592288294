import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { IonItem, IonList } from "@ionic/react";
import { captureException } from "@sentry/browser";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import * as yup from "yup";

import CurrentUserContext from "@components/context/session-context";
import Form from "@components/forms/form";
import Input from "@components/forms/input";
import SubmitButton from "@components/forms/submit-button";
import useMountedTracking from "@hooks/use-mounted-tracking";
import useMutationErrorHandler from "@hooks/use-mutation-error-handler";
import { CreateSessionDocument, CreateSessionMutation, CreateSessionMutationVariables } from "@typing/__generated__";
import isNotNullOrUndefined from "@utils/is-not-null-or-undefined";

type FormData = {
  email: string;
  password: string;
};

type Props = {
  redirectTo?: string;
};

const LoginForm = ({ redirectTo = "/" }: Props) => {
  const history = useHistory();
  const intl = useIntl();
  const { setSession } = useContext(CurrentUserContext);
  const isMounted = useMountedTracking();

  const defaultValues: FormData = {
    email: "",
    password: ""
  };

  const validationSchema: yup.ObjectSchema<FormData> = yup.object().shape({
    email: yup
      .string()
      .email(intl.formatMessage({ id: "components.LoginForm.errors.email.format" }))
      .required(intl.formatMessage({ id: "components.LoginForm.errors.email.required" })),
    password: yup.string().required(intl.formatMessage({ id: "components.LoginForm.errors.password.required" }))
  });

  // The onChange mode is not that efficient, but because we only enable the submit button once the
  // form is valid we do want the validation schema to check on every change.
  const form = useForm<FormData>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  });

  const errorHandler = useMutationErrorHandler({ form, intlBase: "components.LoginForm.errors" });

  const [createSession] = useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument);

  const handleSubmit = () => {
    createSession({ variables: form.getValues() })
      .then(response => {
        const createSessionPayload = response.data?.createSession;
        if (!isNotNullOrUndefined(createSessionPayload)) return response;
        if (errorHandler(createSessionPayload)) return response;

        const session = createSessionPayload.session;
        if (isNotNullOrUndefined(session)) {
          if (isMounted.current) {
            setSession(session);
            history.push(redirectTo);
          }
          return session;
        }
        return response;
      })
      .catch((error: unknown) => {
        captureException(error);
      });
  };

  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <IonList>
        <IonItem lines="full">
          <Input
            errorText={form.formState.errors.email?.message}
            inputmode="email"
            label={intl.formatMessage({ id: "components.LoginForm.fields.email.label" })}
            placeholder={intl.formatMessage({ id: "components.LoginForm.fields.email.placeholder" })}
            register={form.register("email")}
            type="email"
          />
        </IonItem>
        <IonItem lines="full">
          <Input
            errorText={form.formState.errors.password?.message}
            label={intl.formatMessage({ id: "components.LoginForm.fields.password.label" })}
            placeholder={intl.formatMessage({ id: "components.LoginForm.fields.password.placeholder" })}
            register={form.register("password")}
            type="password"
          />
        </IonItem>
      </IonList>
      <SubmitButton form={form} messageId="components.LoginForm.submit" />
    </Form>
  );
};

export default LoginForm;
