import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";

import { ValidationErrorFragment } from "@typing/__generated__";

type MutationResponse = {
  errors: ValidationErrorFragment[];
};

type Arguments<FormData extends FieldValues> = {
  form: UseFormReturn<FormData>;
  intlBase: string;
};

const useMutationErrorHandler = <FormData extends FieldValues>({ form, intlBase }: Arguments<FormData>) => {
  const intl = useIntl();

  const handleErrors = (response: MutationResponse) => {
    if (response.errors.length > 0) {
      for (const validationError of response.errors) {
        const errorMessageId = `${intlBase}.${validationError.field}.${validationError.type ?? "default"}`;
        const errorKey =
          validationError.field in form.control._fields ? (validationError.field as Path<FormData>) : "root";
        form.setError(errorKey, { message: intl.formatMessage({ id: errorMessageId }) });
      }
      return true;
    }
    return false;
  };

  return handleErrors;
};

export default useMutationErrorHandler;
