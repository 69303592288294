import { createContext, Dispatch, SetStateAction } from "react";

import { CommunityFragment } from "@typing/__generated__";

type CurrentCommunityContextType = {
  currentCommunity: CommunityFragment | null;
  setCurrentCommunity: Dispatch<SetStateAction<CommunityFragment | null>>;
};

const CurrentCommunityContext = createContext<CurrentCommunityContextType>({
  currentCommunity: null,
  setCurrentCommunity: () => {
    // eslint-disable-next-line no-console
    console.log("setCurrentCommunity is not implemented");
  }
});

export default CurrentCommunityContext;
