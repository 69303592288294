import { IonButton } from "@ionic/react";
import { ComponentProps, useContext } from "react";

import DemoModalContext from "@components/context/demo-modal-context";

type Props = Omit<ComponentProps<typeof IonButton>, "href" | "onClick" | "routerLink">;

const RequestDemoButton = (props: Props) => {
  const { demoModalOpener } = useContext(DemoModalContext);

  return <IonButton className="text-bold" color="primary" fill="solid" onClick={demoModalOpener?.open} {...props} />;
};

export default RequestDemoButton;
